import { URL_PATH } from '@/consts/urls'

export const EVENT_MESSAGES = {} as const

// 공통 Header의 높이
export const HEADER_HEIGHT = 58

export const ALL_REQUEST_TYPE = 0
export const NOT_ALL_REQUEST_TYPE = 1
export const TOTAL_CANCER_TYPE = 'ALL'

export const CHANNEL_TALK_PAGES = {
  [URL_PATH.MyCare]: URL_PATH.MyCare,
} as const
export const CHANNEL_TALK_LINK = 'https://lunitcare.channel.io/user-chats/new'

export const COMPANY = {
  NAME: '(주)루닛케어',
  CEO: '박은수',
  BUSINESS_NUMBER: '861-86-02504',
  ADDRESS: '서울특별시 강남구 강남대로 364, 13층 1310호',
  EMAIL: 'care.support@lunit.care',
  TELEPHONE: '070-7575-3105',
} as const
export const HOTLINE_TELEPHONE = '070-7174-3737'

// 비로그인 유저가 질문과 답변 게시글을 이 값 초과하여 클릭하면 로그인 유도 바텀시트를 보여준다.
export const PV_THRESHOLD = 2

// Base64 id 인코딩/디코딩
// base64 id 값을 디코딩하면 prefix:{number} 예: guide:1234
export const BASE64_GUIDE_PREFIX = 'guide'
export const BASE64_DOCUMENT_PREFIX = 'document' // 질문과 답변과 병원정보의 prefix는 동일.
export const BASE64_USER = 'user'
export const BASE64_ID_REGEX = new RegExp(
  `^(${BASE64_GUIDE_PREFIX}|${BASE64_DOCUMENT_PREFIX}|${BASE64_USER}):(\\d+)$`,
)
// 숫자만 포함된 문자열을 검증하는 정규식
export const ID_PATTERN = /^\d+$/

export const ANDROID_APP_STORE =
  'https://play.google.com/store/apps/details?id=care.lunit.LunitCARE'
// TODO: 앱스토어 URL 변경 필요
export const IOS_APP_STORE = 'https://apps.apple.com/app/6477522592'

// 쿼리 파라미터를 들고 사이트에 진입 시, 해당 쿼리 파라미터 값을 세션 스토리지에 저장할 때 사용하는 키
export const RCODE = '__rcode__'
export const NBT = '__nbt__'
export const HAS_IDENTIFIED_KEY = '__has_identified__'
export const UNREGISTER_KEY = '__unregister_reason__'

export * from './appVersion'
export * from './board'
export * from './complete'
export * from './guide'
export * from './hospital'
export * from './main'
export * from './mycare'
export * from './styles'
export * from './errors'
export * from './fontSize'
export * from './messages'
export * from './metaTitles'
export * from './urls'
export * from './auth'
export * from './cancer'
export * from './event'
export * from './token'
export * from './status'
export * from './mobileEvents'
export * from './layout'
