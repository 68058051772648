'use client'

import { useEffect, useRef, useState, MutableRefObject } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import { useAtomValue } from 'jotai'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperProps, SwiperSlide, SwiperClass } from 'swiper/react'
import { Cancers, QNA_PLACEHOLDER, MAX_QNA_CONTENT_SIZE } from '@/consts'
import { SWIPER_SETTING } from '@/consts/swiperSetting'
import { aiQuestionAtom } from '@/stores'
import Button from '@/v1/Button'
import LimitedTextarea from '@/v1/LimitedTextarea'
import MaterialSymbol from '@/v1/MaterialSymbol'
import { BoardCreateForm, boardCreateSchema } from '../../types'
import useCreateBoard from '../../useCreateBoard'
import { getPreviousFormData, getUserDataTemplate } from '../utils'
import Header from './Header'
import Loading from './Loading'

const settings: SwiperProps = {
  ...SWIPER_SETTING,
  initialSlide: 0,
  modules: [...SWIPER_SETTING.modules, Pagination],
  pagination: {
    el: '.pagination-indicator',
    type: 'bullets',
    renderBullet: (_: number, className: string) =>
      `<span class="${className} w-[6px] h-[6px] !bg-lavendar-500 mx-1.5"></span>`,
  },
  onSwiper: () => {
    // Swiper 초기화 후, 슬라이드 높이 계산
    setTimeout(() => {
      calculateMaxHeight()
    }, 100)
  },
  onUpdate: (swiper: SwiperClass) => {
    swiper.slideTo(0, 0) // 슬라이드 변경 시, 첫 번째 슬라이드로 이동
    // Swiper 슬라이드 변경 후, 슬라이드 높이 계산
    setTimeout(() => {
      calculateMaxHeight()
    }, 100)
  },
}

/**
 * Swiper 슬라이드의 높이를 동일하게 맞추기 위한 함수
 * Swiper 문서에서 자동 높이 조정은 각 슬라이드 별로 적용되며, 전체 슬라이드의 높이를 동일하게 맞추지 않음
 * 따라서 손수 슬라이드 DOM 엘리먼트를 설정하고, 그 자식 요소는 부모 높이의 100%를 설정하여 높이를 맞춤
 */
const calculateMaxHeight = () => {
  const slides = document.querySelectorAll<HTMLDivElement>(
    '.js-ai-questions .swiper-slide',
  )
  // 슬라이드 배열에서 가장 높은 슬라이드의 높이 계산
  const maxSlideHeight = Array.from(slides).reduce((maxHeight, slide) => {
    return Math.max(maxHeight, slide.offsetHeight)
  }, 0)

  // 모든 슬라이드의 높이를 최대 높이로 설정
  slides.forEach((slide) => {
    slide.style.height = `${maxSlideHeight}px`
  })
}

const LOADING_TEXT = 'loading...'

/*
 * AI 추천 질문 리스트 + 질문 작성 폼
 */
const AIRecommendations = () => {
  const [activeIndex, setActiveIndex] = useState(-1)
  const textareaRef: MutableRefObject<HTMLTextAreaElement | null> = useRef(null)
  const {
    getValues,
    setValue,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
    control,
    reset,
    register,
    watch,
  } = useForm<BoardCreateForm>({
    mode: 'onChange',
    defaultValues: {
      question: '',
      cancer: '' as Cancers,
    },
    resolver: zodResolver(boardCreateSchema),
    shouldFocusError: false,
  })
  const { onSubmit, onError } = useCreateBoard({
    handleRetry: () => {
      onSubmit(getValues())
    },
    reset,
  })
  const { questions, state } = useAtomValue(aiQuestionAtom)
  const selectedCancer = getPreviousFormData()?.cancer
  const { question } = watch()

  const swiperSettings = {
    ...settings,
    onUpdate: (swiper: SwiperClass) => {
      settings.onUpdate?.(swiper)
      setActiveIndex(-1) // 슬라이드 변경 시, 선택된 질문 초기화
    },
  }

  const selectQuestion = (index: number) => {
    // template string에 빈 줄을 넣어 줄바꿈을 위한 공간을 만든다.
    const question = `${getUserDataTemplate()} 

${questions[index]}`

    setActiveIndex(index)
    setValue('question', question, {
      shouldDirty: true,
      shouldValidate: true,
    })
    if (textareaRef.current) {
      textareaRef.current.focus() // ref를 통해 텍스트 영역에 포커스 설정
    }
  }

  const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    // 커서 위치를 해당 텍스트 뒤에 위치시킨다
    event.target.selectionStart = event.target.value.length
  }

  const onSubmitForm = (data: BoardCreateForm) => {
    onSubmit(data)
  }

  useEffect(() => {
    if (selectedCancer) {
      setValue('cancer', selectedCancer as Cancers, {
        shouldValidate: true,
      })
    }
  }, [setValue, selectedCancer])

  useEffect(() => {
    // 입력한 질문을 삭제했는데, 질문 선택이 활성화된 경우, 선택을 해제
    if (!question && activeIndex > -1) {
      setActiveIndex(-1)
    }
  }, [question, activeIndex])

  useEffect(() => {
    // 입력된 질문이 있는데, 질문 추천 state가 로딩(재요청) 중인 경우, 입력한 질문 초기화
    if (question && state === 'loading') {
      setValue('question', '')
    }
  }, [question, state, setValue])
  if (!questions.length && state === 'default') return null

  return (
    <div className="relative h-full js-ai-questions">
      <Header />
      <Swiper {...swiperSettings} className="w-full -mt-4">
        {/* 로딩 스피너를 보여주기 위해, 더미 데이터 사용하여 영역 확보 */}
        {(state === 'done'
          ? questions
          : [LOADING_TEXT, LOADING_TEXT, LOADING_TEXT]
        ).map((question, index) => (
          <SwiperSlide
            key={
              state === 'done'
                ? `${question.slice(0, 10)}-${index}`
                : `${question}-${index}`
            }
          >
            <div
              className="p-4 button-role"
              data-ga="board_create_pick"
              role="button"
              onClick={() => selectQuestion(index)}
              onKeyUp={(e) => e.key === 'Enter' && selectQuestion(index)}
            >
              <div
                className={clsx(
                  'flex',
                  'flex-col',
                  'justify-between',
                  'h-full',
                  'prose-p2',
                  'shadow-wrapper',
                  'rounded-2xl',
                  'mx-2',
                  'px-md',
                  'pt-md',
                  'border',
                  { 'border-lavendar-200': activeIndex === index },
                  { 'border-white': activeIndex !== index },
                  { 'bg-lavendar-100': activeIndex === index },
                )}
              >
                <p className="mb-md v1-tablet:max-h-12 v1-mobile:max-h-24">
                  {question}
                </p>
                <label className="flex items-center font-medium prose-p3 text-lavendar-500 pt-3 pb-md">
                  <MaterialSymbol
                    name="add"
                    size={16}
                    className={clsx('inline-block', 'fill-lavendar-500', {
                      hidden: activeIndex === index,
                    })}
                  />
                  <MaterialSymbol
                    name="check"
                    size={16}
                    className={clsx('fill-lavendar-500', {
                      'inline-block': activeIndex === index,
                      hidden: activeIndex !== index,
                    })}
                  />
                  <span>이 질문 사용하기</span>
                </label>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="pagination-indicator w-full text-center -mt-[9px] mb-[15px]" />
      </Swiper>
      <form
        className={clsx('relative', 'py-6', 'px-md')}
        onSubmit={handleSubmit(onSubmitForm, onError)}
      >
        <h2 className="prose-h5 mb-2">질문 내용을 작성해 주세요</h2>
        <div className="flex items-center text-grey-600 prose-p3 gap-x-1 mb-4">
          <MaterialSymbol name="info" className="fill-grey-600" size={16} />
          직접 내용을 추가하거나 수정할 수 있습니다.
        </div>
        <input {...register('cancer')} type="hidden" />
        <Controller
          name="question"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <LimitedTextarea
              placeholder={QNA_PLACEHOLDER}
              maxLength={MAX_QNA_CONTENT_SIZE}
              className="p-0"
              key={`question-${activeIndex}`}
              {...field}
              onFocus={handleFocus}
              autoFocus={!!question}
              ref={(e) => {
                field.ref(e) // react-hook-form의 ref
                textareaRef.current = e // 부모 컴포넌트의 ref도 설정
              }}
            />
          )}
        />
        <Button
          type="submit"
          size="large"
          className="mt-md"
          disabled={!isDirty || !isValid || isSubmitting || state !== 'done'}
        >
          작성 완료
        </Button>
      </form>
      {state === 'loading' && <Loading />}
    </div>
  )
}

export default AIRecommendations
