import clsx from 'clsx'

export type SpinnerSizeType = 'sm' | 'md' | 'lg' | 'xl'

export interface SpinnerProps {
  size?: SpinnerSizeType
}

const SpinnerSize = {
  sm: 16,
  md: 20,
  lg: 40,
  xl: 64,
}

/**
 * 스피너 컴포넌트
 * @param {'sm' | 'md' | 'lg' | 'xl'} [props.size='md'] - Spinner 크기
 */
const Spinner = ({ size = 'md' }: SpinnerProps) => {
  return (
    <div
      className={clsx(
        'w-full',
        'h-full',
        'flex',
        'justify-center',
        'items-center',
      )}
    >
      <img
        width={SpinnerSize[size]}
        height={SpinnerSize[size]}
        src="/assets/images/spinner.svg"
        alt=""
        className="animate-spin"
      />
    </div>
  )
}

export default Spinner
