import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useForm, Controller, FieldErrors } from 'react-hook-form'
import { useRouter } from 'next/navigation'
import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import { useSWRConfig } from 'swr'
import CancerTypeRadioInputGroup from '@/components/CancerTypeRadioInputGroup'
import Complete from '@/components/Complete'
import Dvh100 from '@/components/Dvh100'
import {
  QNA_PLACEHOLDER,
  MAX_QNA_CONTENT_SIZE,
  MESSAGES,
  COMPLETE_MAIN_TEXT,
  COMPLETE_SUB_TEXT,
  HEADER_HEIGHT,
  API_PATH,
  KOR_TO_ENG_CANCER_MAP,
  KorCancerType,
  URL_PATH,
  BOTTOM_PADDING_STYLE,
} from '@/consts'
import ImageUploader from '@/containers/BoardCreate/ImageUploader'
import {
  BoardCreateForm,
  BoardCreateResponse,
  boardCreateSchema,
  BoardUpdateForm as BoardUpdateFormType,
} from '@/containers/BoardCreate/types'
import { useModal } from '@/hooks/useModal'
import useFullScreenConfirmableModal, {
  checkCloseConfirmation,
} from '@/hooks/useModal/useFullScreenConfirmableModal'
import { BoardResponse } from '@/types'
import { getAuth } from '@/utils/auth'
import { handleImageUpload } from '@/utils/board/handleImageUpload'
import { showErrorAlert } from '@/utils/error'
import { handleCustomError, handleError } from '@/utils/httpClient'
import {
  handleRequest,
  handleTokenRefresh,
} from '@/utils/httpClient/handleRequest'
import LimitedTextarea from '@/v1/LimitedTextarea'

export const handleUpdate = async (id: number, data: BoardUpdateFormType) => {
  try {
    const response = await handleRequest<BoardCreateResponse>(
      `${API_PATH.Qna}${id.toString()}/`,
      {
        method: 'PUT',
        json: data,
      },
    )
    return response
  } catch (error) {
    throw error
  }
}

const BUTTON_HEIGHT = 50
// BOTTOM_PADDING_STYLE 추가
const OFFSET = HEADER_HEIGHT - BUTTON_HEIGHT + 120

const BoardUpdateForm = ({ data }: { data: BoardResponse }) => {
  const { push } = useRouter()
  // imageFiles는 최초에 null이고, 명시적으로 이미지를 제거할 때는 빈 배열이 된다.
  const [imageFiles, setImageFiles] = useState<File[] | null>(null)
  const [totalImageCount, setTotalImageCount] = useState(data.images.length)
  const { id, question, cancer } = data

  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<BoardCreateForm>({
    mode: 'onChange',
    defaultValues: {
      cancer: KOR_TO_ENG_CANCER_MAP[cancer.name as KorCancerType],
      question: question,
    },
    resolver: zodResolver(boardCreateSchema),
    shouldFocusError: false,
  })

  const { alertModal, fullScreenModal } = useModal()
  const { modal: fullScreenConfirmableModal } = useFullScreenConfirmableModal()
  const { mutate } = useSWRConfig()

  const handleRetry = () => {
    const values = getValues()
    onSubmit(values)
  }

  // 서버에 존재하는 이미지들 삭제 요청
  const handleImagesDelete = async () => {
    try {
      for (const image of data.images) {
        await handleRequest(`${API_PATH.Qna}${id}/image/${image.id}/`, {
          method: 'delete',
        })
      }
    } catch (error) {
      throw error
    }
  }

  const handleImageUpdate = async () => {
    // 업로드한 이미지가 없으면 return
    if (!imageFiles || (imageFiles?.length === 0 && data.images.length === 0))
      return
    const formData = new FormData()

    // FormData에 파일 추가
    imageFiles?.forEach((file) => {
      formData.append('images', file)
    })

    try {
      // 먼저 서버에 기존 이미지들을 delete 요청을 하고
      await handleImagesDelete()
      // 이미지를 제거한 경우
      if (imageFiles.length === 0) return
      // 새로운 이미지들로 업로드 요청한다
      await handleImageUpload(id, formData)
    } catch (error) {
      throw error
    }
  }

  const onSubmit = async (data: BoardCreateForm) => {
    const { isLoggedIn } = getAuth()
    if (!isLoggedIn) {
      handleTokenRefresh().then(handleRetry).catch(handleError)
      return
    }

    try {
      await handleUpdate(id, data)

      TagManager.dataLayer({
        dataLayer: {
          event: 'update_board',
          contents_id: id,
          cancertype: data.cancer,
        },
      })

      // 수정 완료 시 완료 모달 띄우기
      fullScreenModal.show({
        children: (
          <Complete
            type="boardUpdate"
            mainContent={COMPLETE_MAIN_TEXT.boardUpdate}
            subContent={COMPLETE_SUB_TEXT}
            id={id}
          />
        ),
        onClose: () => {
          push(`${URL_PATH.MyCareBoards}${id}/`)
        },
      })
      await handleImageUpdate()
      await mutate(`${API_PATH.Qna}${id.toString()}/`)
    } catch (error) {
      handleCustomError({ error, ignoreCodes: [403] }).then(
        ({ code, errorResponse }) => {
          if (code === 403) {
            return showErrorAlert(String(errorResponse), () => {
              push(`${URL_PATH.MyCareBoards}${id}/`)
              fullScreenModal.hide()
            })
          }
        },
      )
    } finally {
      fullScreenConfirmableModal.hide()
    }
  }

  const onError = (errors: FieldErrors<BoardCreateForm>) => {
    if (errors.question) {
      alertModal.show({
        message: MESSAGES.CONTENT_REQUIRED,
      })
    }
  }

  // 질문 수정 중에는, 풀스크린 모달 닫기 전 컨펌창으로 확인을 받는다.
  useEffect(() => {
    checkCloseConfirmation(isDirty)
  }, [isDirty])

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Dvh100
        className={clsx('scrollbar-hide', BOTTOM_PADDING_STYLE)}
        offset={OFFSET}
      >
        <div className="flex flex-col">
          <div className={clsx('relative', 'py-6', 'px-md')}>
            <Controller
              render={({ field }) => (
                <>
                  <h2 className="prose-h5 mb-4">질문 내용을 작성해 주세요</h2>
                  <CancerTypeRadioInputGroup
                    register={register}
                    setValue={setValue}
                    defaultValue={
                      KOR_TO_ENG_CANCER_MAP[cancer.name as KorCancerType]
                    }
                  />
                  <LimitedTextarea
                    placeholder={QNA_PLACEHOLDER}
                    maxLength={MAX_QNA_CONTENT_SIZE}
                    className="p-0"
                    {...field}
                  />
                </>
              )}
              name="question"
              control={control}
              defaultValue={question}
            />
          </div>
        </div>
        <ImageUploader
          thumbnailImage={
            data.images.length > 0 ? data.images[0].image : undefined
          }
          totalImages={totalImageCount}
          selectImages={(images: File[]) => {
            setImageFiles(images)
            setTotalImageCount(images.length)
          }}
        />
      </Dvh100>
      <button
        type="submit"
        disabled={
          // 서버 요청 중이거나
          isSubmitting ||
          // 에러가 있거나
          !!Object.keys(errors).length ||
          // 이미지와 폼 변경사항이 없으면 비활성화
          // 선택한 이미지가 없거나, 이미지 선택 해제한 경우
          (!isDirty &&
            !imageFiles?.length &&
            data.images.length === totalImageCount)
        }
        className={clsx(
          'button-fill-primary',
          'button-large',
          'w-full',
          'max-w-[768px]',
          'v1-mobile:max-w-full',
          'rounded-none',
          'fixed',
          'bottom-0',
        )}
        data-ga="board_update_submit"
      >
        수정 완료
      </button>
    </form>
  )
}

export default BoardUpdateForm
