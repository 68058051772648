import clsx from 'clsx'
import Spinner, { SpinnerSizeType } from '.'

/**
 * Spinner의 size와 감싼 스피너 style를 지정할 수 있는 컴포넌트
 *
 * @param {'sm' | 'md' | 'lg' | 'xl'} [props.size='md'] - Spinner 크기
 */
const SizedSpinner = ({
  size = 'md',
  className = '',
}: {
  size?: SpinnerSizeType
  className?: string
}) => {
  return (
    <div
      className={clsx(
        'flex',
        'justify-center',
        'items-center',
        'h-full',
        className,
      )}
    >
      <Spinner size={size} />
    </div>
  )
}
export default SizedSpinner
