'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'
import clsx from 'clsx'
import { useAtom, useSetAtom } from 'jotai'
import Dvh100 from '@/components/Dvh100'
import { CloseIcon } from '@/components/NewIcons'
import { env } from '@/config'
import {
  BOTTOM_PADDING_STYLE,
  GNB_MENUS,
  HEADER_HEIGHT,
  URL_PATH,
  PLUS_APP_VERSION,
} from '@/consts'
import useAuth from '@/hooks/useAuth'
import useToggleBodyScroll from '@/hooks/useToggleBodyScroll'
import { mainScrollTopAtom } from '@/stores'
import { gnbOpenAtom } from '@/stores/gnb'
import {
  checkOldAppVersion,
  getBuildNumberFromCookie,
  isWebview,
} from '@/utils'
import Chip from '@/v1/Chip'
import { V1_TABLET_WIDTH } from '@/v1/Layout/styles'
import LogoHomeButton from '@/v1/LogoHomeButton'
import BetaBadge from './BetaBadge'
import { MYCARE_URLS } from './consts'
import LoginMenu from './LoginMenu'
import NewNotiLink from './NewNotiLink'
import SocialMediaMenu from './SocialMediaMenu'
import SubMenu from './SubMenu'

const Gnb = () => {
  const pathname = usePathname()
  const setMainScrollTop = useSetAtom(mainScrollTopAtom)
  const [isGnbOpen, setIsGnbOpen] = useAtom(gnbOpenAtom)
  const isApp = isWebview()
  const { isLoggedIn } = useAuth()
  const href = isLoggedIn ? URL_PATH.MyCare : URL_PATH.GetStarted
  // 루케플 버전 1.0.0 이후 앱에서만 루케플 링크 노출
  const isPrePlusApp = checkOldAppVersion(PLUS_APP_VERSION)

  useToggleBodyScroll(isGnbOpen)

  if (!isGnbOpen) return null

  const closeModal = () => {
    setIsGnbOpen(false)
    setMainScrollTop(0)
  }

  return (
    <>
      <Dvh100
        className={clsx(
          'fixed',
          'inset-0',
          V1_TABLET_WIDTH,
          'mx-auto',
          'w-full',
          'transition-all',
          'ease-in-out',
          'z-20',
          {
            'opacity-100': isGnbOpen,
            '-right-full opacity-0 invisible': !isGnbOpen,
          },
          { 'bg-white': isApp, 'bg-grey-100': !isApp },
          { 'pb-16': isApp },
        )}
        offset={0}
      >
        <div
          className="flex justify-between items-center w-full bg-white"
          style={{ height: HEADER_HEIGHT }}
        >
          <LogoHomeButton handleClick={closeModal} />
          {!isApp && (
            <button type="button" onClick={closeModal} className="px-6 h-full">
              <CloseIcon className="!fill-black" />
            </button>
          )}
        </div>

        <div className="bg-white">
          <LoginMenu />
          <div className="px-md">
            <div className="py-5">
              <Link
                href={href}
                data-ga="menu_mycare"
                onClick={closeModal}
                className={clsx('block', 'py-1.5', 'font-bold', 'text-lg', {
                  'text-lavendar-500': MYCARE_URLS.includes(pathname ?? ''),
                })}
              >
                마이케어
              </Link>
              <NewNotiLink onClick={() => setIsGnbOpen(false)} />
            </div>

            {GNB_MENUS.map(
              ({ isUse, menu, subMenus }, idx) =>
                isUse && (
                  <div
                    key={`menu-${idx}`}
                    className="py-5 border-t border-t-grey-100"
                  >
                    {menu && (
                      <div className="text-grey-600 text-sm leading-6 mb-2">
                        {menu}
                      </div>
                    )}
                    <ul
                      className={clsx({
                        'grid grid-cols-2':
                          menu === '주제별 암 정보' ||
                          menu === '루닛케어플러스',
                      })}
                    >
                      {subMenus.map((subMenu, idx) => {
                        const isHighlighted = subMenu.highlightPaths.includes(
                          pathname ?? '',
                        )
                        return (
                          <li
                            key={`sub-menu-${idx}`}
                            className={clsx(
                              'font-bold',
                              'text-lg',
                              'mb-3',
                              'last:mb-0',
                              {
                                'text-lavendar-500': isHighlighted,
                              },
                            )}
                          >
                            <div className="flex items-center">
                              <SubMenu
                                subMenu={subMenu}
                                closeModal={closeModal}
                              />
                              {subMenu.isBeta && <BetaBadge />}
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                ),
            )}
          </div>
          {isApp && !isPrePlusApp && (
            <div className={clsx('px-md', 'mb-5', 'inline-block')}>
              <Link href={URL_PATH.MyPlusSurveyResult}>루케플 링크</Link>
            </div>
          )}

          {env !== 'production' && (
            <div
              className={clsx('px-md', 'mb-5', 'inline-block', {
                [BOTTOM_PADDING_STYLE]: isApp,
              })}
            >
              <Chip text={`${env}${isApp ? '-webview' : ''}`} />
              {isApp && (
                <>
                  <div className="my-1 ml-1 text-sm break-all">
                    {location.host}
                  </div>
                  <div className="my-1 ml-1 text-sm break-all">
                    build number {getBuildNumberFromCookie() ?? '-'}
                  </div>
                </>
              )}
            </div>
          )}
          <SocialMediaMenu />
        </div>
      </Dvh100>
      {!isApp && isGnbOpen && (
        <div
          className={clsx(
            'fixed',
            'inset-0',
            'h-full',
            'bg-black',
            'opacity-80',
            'cursor-default',
            'z-[15]',
          )}
          role="button"
          tabIndex={0}
          onClick={closeModal}
        />
      )}
    </>
  )
}

export default Gnb
