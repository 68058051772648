/**
 * (도움됨/도움 되지 않음) 두 가지 중 선택하는 바텀시트
 */
import { useSWRConfig } from 'swr'
import { API_PATH } from '@/consts/urls'
import { useModal } from '@/hooks/useModal'
import Button from '@/v1/Button'
import MaterialSymbol from '@/v1/MaterialSymbol'
import { BOTTOMSHEET_CLOSE_GA_SELECTOR } from './consts'
import FeedbackBottomSheet from './FeedbackBottomSheet'
import { AnswerType, FeedbackContentType, FeedbackProps } from './types'

const ThumbsUpDownFeedback = ({
  contentId,
  contentType,
  reasons,
}: FeedbackProps) => {
  const { mutate } = useSWRConfig()
  const { bottomSheetModal } = useModal()

  const getOnSuccessProps = (type: FeedbackContentType) => {
    // 내가 작성한 게시글의 경우 피드백은 한 번만 제출 가능하므로, 완료 후에 mutate하여 피드백 영역을 노출하지 않는다.
    if (type === FeedbackContentType.qna_me)
      return {
        onUpdateSuccess: () => mutate(`${API_PATH.Qna}${contentId}/`),
      }

    if (type === FeedbackContentType.qna_ai_answer)
      return {
        onUpdateSuccess: () => mutate(`${API_PATH.Qna}${contentId}/ai-answer/`),
      }

    return {}
  }

  const showBottomSheet = (type: AnswerType) => {
    bottomSheetModal.show({
      header: {
        title: '',
        size: 'medium',
        closeGaSelector: BOTTOMSHEET_CLOSE_GA_SELECTOR,
      },
      content: (
        <FeedbackBottomSheet
          contentId={contentId}
          contentType={contentType}
          thumbsUp={Boolean(type === 'positive')}
          options={reasons?.[type]}
          {...getOnSuccessProps(contentType)}
        />
      ),
    })
  }

  return (
    <div className="py-8 px-md text-center">
      <p className="prose-h5">
        루닛케어에서 제공하는 정보가 <br /> 도움이 되었다고 생각하시나요?
      </p>
      <p className="text-grey-700 prose-p3 font-medium mt-2">
        아래 버튼을 선택해 주세요.
      </p>
      <div className="mt-4 flex gap-x-3">
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          leftIcon={<MaterialSymbol name="thumb_up" size={16} />}
          onClick={() => showBottomSheet('positive')}
        >
          도움됨
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          leftIcon={<MaterialSymbol name="thumb_down" size={16} />}
          onClick={() => showBottomSheet('negative')}
        >
          도움 되지 않음
        </Button>
      </div>
    </div>
  )
}

export default ThumbsUpDownFeedback
