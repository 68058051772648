/* eslint-disable react/no-children-prop */
import dynamic from 'next/dynamic'
import clsx from 'clsx'
import parse, {
  DOMNode,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser'
import Aside from './Aside'
import Img from './Img'

const GlossaryPopoverComponent = dynamic(() => import('../GlossaryPopover'), {
  ssr: false,
})

// TODO: ckeditor에서 인라인 스타일을 제거해야 한다.
const removeInlineStyle = (domNode: Element) => {
  if (!(domNode instanceof Element) || !domNode.attribs) return
  // style 속성 완전 제거
  if ('style' in domNode.attribs) delete domNode.attribs.style
}

const HtmlViewer = ({
  content,
  wordBreak = 'break-all',
}: {
  content: string
  wordBreak?: 'break-keep' | 'break-all'
}) => {
  const options: HTMLReactParserOptions = {
    replace(domNode) {
      if (domNode instanceof Element) removeInlineStyle(domNode)

      // 'data-popover-button' 속성이 존재하는 버튼의 경우 GlossaryPopover로 렌더링한다.
      if (
        domNode instanceof Element &&
        domNode.name === 'button' &&
        domNode.attribs['data-popover-button'] !== undefined
      ) {
        const word =
          typeof window !== 'undefined' &&
          domNode.childNodes[0].nodeType === Node.TEXT_NODE
            ? (domNode.childNodes[0] as unknown as Text).data
            : ''

        return (
          <GlossaryPopoverComponent word={word} attribs={domNode.attribs} />
        )
      }
      // 이미지 태그의 경우 next/image로 대체한다.
      if (domNode instanceof Element && domNode.name === 'img') {
        return <Img {...domNode.attribs} />
      }
      // 구버전 aside 태그 스타일링
      if (domNode instanceof Element && domNode.name === 'aside') {
        return (
          <Aside
            attribs={domNode.attribs}
            children={domNode.children as DOMNode[]}
          />
        )
      }
      if (domNode instanceof Element && domNode.name === 'oembed') {
        const url = domNode.attribs.url.replace(
          'https://youtu.be/',
          'https://www.youtube.com/embed/',
        )
        return (
          <iframe
            src={url}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        )
      }

      return domNode
    },
  }

  return (
    <div className={clsx('ck', 'ck-content', 'relative', wordBreak)}>
      {parse(content, options)}
    </div>
  )
}

export default HtmlViewer
