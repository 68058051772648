import { ReactNode } from 'react'
import NiceModal from '@ebay/nice-modal-react'
import {
  AUTH_PROVIDER,
  ERROR_MESSAGES,
  REFRESH_TOKEN_NOT_FOUND_ERROR,
} from '@/consts'
import AlertModal from '@/hooks/useModal/AlertModal'
import BottomSheetModal from '@/hooks/useModal/BottomSheetModal'
import { loginWithNaver } from '@/utils/naver'
import { parseSearchParams } from '@/utils/url'
import { BottomSheetContent } from '@/v1/Modal/BottomSheet/Content'
import LoginContent from '@/v1/Modal/BottomSheet/Content/LoginContent'

export const showErrorAlert = (message: ReactNode, onSubmit?: () => void) => {
  if (message === REFRESH_TOKEN_NOT_FOUND_ERROR) return
  NiceModal.show(AlertModal, {
    message,
    confirmButton: { text: '확인', onClick: onSubmit },
  })
}

export const showBottomSheet = (redirectPath?: string) => {
  NiceModal.show(BottomSheetModal, {
    header: {
      size: 'xs',
    },
    content: (
      <LoginContent
        description={<BottomSheetContent />}
        redirectPath={redirectPath ?? window.location.pathname}
      />
    ),
  })
}

export const handleServiceAndTermsDenied = () => {
  const { provider, redirectPath } = parseSearchParams()
  if (!provider || provider === AUTH_PROVIDER.KAKAO) {
    return showErrorAlert(ERROR_MESSAGES.SERVICE_AND_TERMS_DENIED, () => {
      window.location.replace('/')
    })
  }
  if (provider === AUTH_PROVIDER.NAVER) {
    loginWithNaver({
      redirectPath,
      authType: 'reprompt',
    })
  }
}

export { setExtra } from './setExtra'
