import { PropsWithChildren, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

type CustomCSSProperties = React.CSSProperties & {
  '--content-offset'?: string // 사용자 정의 CSS 변수 추가
}

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  offset?: number
  className?: string
}

/**
 * 화면 전체 높이를 사용하는 컴포넌트
 * dvh를 지원하는 브라우저에서는 100dvh를 사용하고, 그렇지 않은 브라우저에서는 100vh를 사용한다.
 * offset 높이를 빼고 나머지 전체 높이를 사용한다.
 * @param offset - 높이를 뺄 값
 */
const Dvh100 = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ offset = 0, className = '', children }, ref) => {
    return (
      <div
        className={twMerge('overflow-y-auto', 'h-custom-dvh', className)}
        style={
          {
            '--height-offset': `${offset}px`,
          } as CustomCSSProperties
        }
        ref={ref ? ref : undefined} // ref가 있을 때만 전달
      >
        {children}
      </div>
    )
  },
)

export default Dvh100
Dvh100.displayName = 'Dvh100'
