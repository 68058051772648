import { usePathname } from 'next/navigation'
import { ACTION_TYPES, URL_PATH } from '@/consts'
import BoardCreate from '@/containers/BoardCreate'
import { useModal } from '@/hooks/useModal'
import { BOARD_FULL_MODAL_CONTENT } from '@/hooks/useModal/consts'
import useFullScreenConfirmableModal from '@/hooks/useModal/useFullScreenConfirmableModal'
import { getAuth } from '@/utils/auth'
import { BottomSheetContent } from '@/v1/Modal/BottomSheet/Content'
import LoginContent from '@/v1/Modal/BottomSheet/Content/LoginContent'

type Props = 'bottomsheet' | 'promotion'

export function useCreateBoardModal(kind: Props = 'bottomsheet') {
  const pathname = usePathname()
  const { loginPromotionModal, bottomSheetModal } = useModal()
  const { modal: fullScreenConfirmableModal } = useFullScreenConfirmableModal()

  const showCreateBoardModal = () => {
    fullScreenConfirmableModal.show({
      title: '의료팀에게 질문하기',
      gaCloseSelector: 'board_create_close',
      children: <BoardCreate />,
      confirmMessage:
        '질문을 종료하겠습니까?\n종료하면 작성한 내용이 삭제됩니다.',
      scrollable: false,
    })
  }

  /**
   * 질문작성하기 버튼/배너 등을 클릭했을 때, 로그인 여부에 따라 로그인 모달을 보여주거나, 로그인 바텀시트를 보여주는 일을 하기 위한 함수.
   */
  const handleCreateBoardAuth = () => {
    const { isLoggedIn } = getAuth()
    const redirectPath =
      pathname === `${URL_PATH.GetStarted}` ? URL_PATH.MyCare : pathname
    // 로그인 한 유저에게는 질문하기 모달을 보여준다.
    // TODO: 질문하기 모달 컴퍼넌트 교체.
    if (isLoggedIn) {
      showCreateBoardModal()
      return
    }

    if (kind === 'bottomsheet') {
      bottomSheetModal.show({
        header: {
          size: 'xs',
        },
        content: (
          <LoginContent
            description={<BottomSheetContent />}
            // TODO: hash fragment가 있는 경우 처리할 것.
            redirectPath={`${redirectPath}?${ACTION_TYPES.CREATE_BOARD}`}
          />
        ),
      })
    } else {
      loginPromotionModal.show({
        ...BOARD_FULL_MODAL_CONTENT,
        redirectPath: `${redirectPath}?${ACTION_TYPES.CREATE_BOARD}`,
      })
    }
  }
  return { handleCreateBoardAuth, showCreateBoardModal }
}
