import clsx from 'clsx'

/**
 * 이미지 업로드 갯수를 표시하는 컴포넌트
 * @param maxImageCount - 최대 이미지 갯수
 * @param totalImageCount - 총 이미지 갯수
 */
const ImageCount = ({
  maxImageCount,
  totalImageCount,
}: {
  maxImageCount: number
  totalImageCount: number
}) => {
  return (
    <span
      className={clsx(
        'text-white',
        'bg-grey-800',
        'prose-p4 font-medium',
        'py-[3px] px-1.5',
        'rounded-lg',
        'absolute',
        'top-[38px]',
        'left-1/2 -translate-x-1/2',
        'whitespace-nowrap',
      )}
    >
      {totalImageCount > maxImageCount ? maxImageCount : totalImageCount} /{' '}
      {maxImageCount}
    </span>
  )
}

export default ImageCount
