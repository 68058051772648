import { Scope } from '@sentry/types'
import { getPlatformFromCookie, getVersionFromCookie } from '@/utils'

/**
 * Sentry의 scope에 추가 정보를 설정한다.
 * @param scope Sentry의 Scope 객체
 * @returns 업데이트된 Scope 객체
 */
export const setExtra = (scope: Scope) => {
  const appPlatform = getPlatformFromCookie()
  const appVersion = getVersionFromCookie()
  scope.clear()
  scope.setExtra('appVersion', appVersion)
  scope.setExtra('appPlatform', appPlatform)
  return scope
}
