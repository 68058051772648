'use client'

import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { useSetAtom } from 'jotai'
import { S3_IMAGE_URL } from '@/config'
import { URL_PATH } from '@/consts'
import { useModal } from '@/hooks/useModal'
import { mainBottomSheetAtom } from '@/stores'
import Button from '@/v1/Button'
import MaterialSymbol from '@/v1/MaterialSymbol'

/**
 * 세션 기준 메인페이지에 최초 한 번 노출되는 AI 진료노트 바텀시트
 */
const MainBottomSheet = () => {
  const setMainBottomSheet = useSetAtom(mainBottomSheetAtom)
  const { push } = useRouter()
  const { bottomSheetModal } = useModal()

  const handleClose = () => {
    bottomSheetModal.hide()
    setMainBottomSheet(false)
  }

  const handleClick = () => {
    push(URL_PATH.HealthCheckupEvent)
    handleClose()
  }

  return (
    <>
      <div className="bg-aqua-100 rounded-t-3xl w-full flex flex-col items-center pt-14 relative text-center">
        <p className="prose-p2 text-aqua-700 font-bold">
          전국민 건강 증진 캠페인
        </p>
        <h4 className="prose-h4 mt-2 mb-3">
          조직검사 결과지 해석, <br />
          의사에게 직접 물어보세요!
        </h4>
        <p className="mb-md">
          루닛케어 암 전문 의료팀이 무료로 <br />꼭 알아두어야 하는 내용들을
          알려드립니다.
        </p>
        <Image
          src={`${S3_IMAGE_URL}/events/health_event/health_event.png`}
          alt=""
          width={272}
          height={227}
        />
      </div>
      <button
        className="absolute top-3 right-3 p-3"
        data-ga="main_health_checkup2024_close"
        onClick={handleClose}
        type="button"
      >
        <MaterialSymbol name="close" />
      </button>

      <div className="p-md">
        <Button
          size="large"
          data-ga="main_health_checkup2024_cta"
          onClick={handleClick}
        >
          조직검사 결과지 무료 해석받기
        </Button>
      </div>
    </>
  )
}

export default MainBottomSheet
