import Image from 'next/image'
import clsx from 'clsx'
import useSWR from 'swr'
import { API_PATH } from '@/consts'
import { MAX_NUM_OF_IMAGES } from '@/containers/BoardCreate/consts'
import type { BoardResponse } from '@/types/board'
import LabelBadge from '@/v1/Badge/LabelBadge'
import Content from './Content'
import CreatedDate from './CreatedDate'
import DeleteButton from './DeleteButton'
import EditButton from './EditButton'
import Title from './Title'

const Question = ({
  title,
  board_id: boardId,
  question,
  cancer,
  created_at,
  myQuestion = false,
}: {
  title: BoardResponse['title']
  board_id: BoardResponse['id']
  question: BoardResponse['question']
  cancer: BoardResponse['cancer']['name']
  created_at: BoardResponse['created_at']
  myQuestion?: boolean
}) => {
  // 기본 답변 데이터
  const { data, isLoading } = useSWR<BoardResponse>(
    myQuestion ? `${API_PATH.Qna}${boardId}/` : null,
  )

  if (isLoading) return null

  return (
    <div className="pt-md px-md pb-8 border-b-[10px] border-b-grey-100">
      <div className="mb-2">
        <LabelBadge label={cancer} />
      </div>
      <Title title={title} />
      <CreatedDate createdAt={created_at} />
      <p className="whitespace-pre-line">
        <Content content={question} />
      </p>
      {myQuestion && data?.images && data.images?.length > 0 && (
        <div className="mt-md">
          <p
            className={clsx(
              'flex',
              'items-center',
              'justify-between',
              'text-grey-800',
            )}
          >
            <strong className="block prose-p1">조직검사 결과지 첨부</strong>
            <span className="block prose-p3">
              ({data.images.length}/{MAX_NUM_OF_IMAGES})
            </span>
          </p>
          <div className="flex flex-wrap gap-2 mt-3">
            {data.images.map(({ id, image }) => (
              <div
                key={`image-${id}`}
                className="relative w-[72px] h-[72px] overflow-hidden rounded-lg"
              >
                <Image
                  className={clsx(
                    'rounded-lg',
                    'absolute',
                    'top-1/2 left-1/2',
                    '-translate-x-1/2',
                    '-translate-y-1/2',
                    'w-auto h-auto',
                    'min-w-[100%] min-h-[100%]',
                    'object-cover object-center',
                  )}
                  alt=""
                  src={image}
                  width={72}
                  height={72}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {myQuestion && (
        <div className="flex items-center mt-6">
          <DeleteButton id={`${boardId}`} cancer={cancer} />
          <EditButton id={`${boardId}`} />
        </div>
      )}
    </div>
  )
}

export default Question
