import clsx from 'clsx'
import FootNote from '@/components/FootNote'
import HtmlViewer from '@/components/HtmlViewer'
import UserFeedback from '@/components/UserFeedback'
import { FeedbackContentType } from '@/components/UserFeedback/types'
import { BOTTOM_PADDING_STYLE } from '@/consts'
import { AIAnswerResponse } from '@/types/board'
import Button from '@/v1/Button'
import MaterialSymbol from '@/v1/MaterialSymbol'

/**
 * AI가 제공한 답변
 * @param data AI 답변
 * @param contentId 게시글 상세 id
 * @param onRequestMedicalAnswer 의료팀에게 답변 요청하기 함수
 * @param isOptionsVisible
 */
const AIAnswer = ({
  data,
  contentId,
  onRequestMedicalAnswer,
  isOptionsVisible,
  hideOptions,
}: {
  data: AIAnswerResponse
  contentId: string
  onRequestMedicalAnswer: () => void
  isOptionsVisible: boolean
  hideOptions: () => void
}) => {
  return (
    <div className={clsx(BOTTOM_PADDING_STYLE, 'px-md')}>
      <HtmlViewer content={data.answer} />
      {data.disclaimer && (
        <FootNote className="mt-md !rounded-[14px]">
          <HtmlViewer content={data.disclaimer} />
        </FootNote>
      )}
      {isOptionsVisible && (
        <div className="rounded-[20px] mt-md flex flex-col justify-center p-5 shadow-wrapper">
          <p className="prose-h6 text-center">
            의료팀 답변을
            <br /> 추가로 받아보시겠어요?
          </p>
          <div className="flex gap-x-3 my-4 w-[272px] mx-auto">
            <Button
              size="medium"
              variant="outlined"
              onClick={onRequestMedicalAnswer}
              data-ga="board_ai_document_doctor_create"
            >
              네
            </Button>
            <Button
              size="medium"
              variant="outlined"
              onClick={hideOptions}
              data-ga="board_ai_document_close"
            >
              아니오
            </Button>
          </div>
          <div className="text-grey-800 prose-p3 flex items-start justify-center gap-x-1">
            <MaterialSymbol
              name="info"
              size={16}
              className="fill-grey-800 mt-0.5"
            />
            <div className="text-center">
              AI 답변은 <strong>마이케어{'>'}질문함</strong>에서 <br />
              확인하실 수 있습니다.
            </div>
          </div>
        </div>
      )}
      {/* 아니오를 클릭했으며, 기존에 피드백 제출 이력이 없는 경우 */}
      {!isOptionsVisible && !data.feedback_id && (
        <UserFeedback
          contentId={contentId}
          type={FeedbackContentType.qna_ai_answer}
        />
      )}
    </div>
  )
}

export default AIAnswer
