import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import CancerTypeRadioInputGroup from '@/components/CancerTypeRadioInputGroup'
import Dvh100 from '@/components/Dvh100'
import {
  QNA_PLACEHOLDER,
  MAX_QNA_CONTENT_SIZE,
  HEADER_HEIGHT,
  Cancers,
  BOTTOM_PADDING_STYLE,
} from '@/consts'
import { checkCloseConfirmation } from '@/hooks/useModal/useFullScreenConfirmableModal'
import LimitedTextarea from '@/v1/LimitedTextarea'
import AIQuestionNudge from './AIQuestionNudge'
import { BUTTON_HEIGHT } from './consts'
import ImageUploader from './ImageUploader'
import { boardCreateSchema, BoardCreateForm } from './types'
import useCreateBoard from './useCreateBoard'

// 바텀 패딩 120px 추가
const OFFSET = HEADER_HEIGHT - BUTTON_HEIGHT + 120

export default function BoardCreate() {
  const [imageFiles, setImageFiles] = useState<File[]>([])

  const {
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm<BoardCreateForm>({
    mode: 'onChange',
    defaultValues: {
      question: '',
      cancer: '' as Cancers,
    },
    resolver: zodResolver(boardCreateSchema),
    shouldFocusError: false,
  })

  const { onSubmit, onError } = useCreateBoard({
    handleRetry: () => {
      onSubmit(getValues(), imageFiles)
    },
    reset,
  })

  const onSubmitForm = (data: BoardCreateForm) => {
    onSubmit(data, imageFiles)
  }

  // 질문 작성 중에는, 풀스크린 모달 닫기 전 컨펌창으로 확인을 받는다.
  useEffect(() => {
    checkCloseConfirmation(isDirty)
  }, [isDirty])

  return (
    <form onSubmit={handleSubmit(onSubmitForm, onError)}>
      <Dvh100
        className={clsx('scrollbar-hide', BOTTOM_PADDING_STYLE)}
        offset={OFFSET}
      >
        <AIQuestionNudge />
        <div className={clsx('relative', 'py-6', 'px-md')}>
          <h2 className="prose-h5 mb-4">질문 내용을 작성해 주세요</h2>
          <CancerTypeRadioInputGroup
            register={register}
            setValue={setValue}
            onSelect={(element) => {
              TagManager.dataLayer({
                dataLayer: {
                  event: 'click_board_create',
                  board_create_cancertype:
                    element.target.getAttribute('data-label'),
                },
              })
            }}
          />
          <Controller
            name="question"
            control={control}
            render={({ field }) => (
              <LimitedTextarea
                placeholder={QNA_PLACEHOLDER}
                maxLength={MAX_QNA_CONTENT_SIZE}
                className="p-0"
                {...field}
              />
            )}
          />
        </div>
        <ImageUploader
          totalImages={imageFiles.length}
          selectImages={(images: File[]) => {
            setImageFiles(images)
          }}
        />
      </Dvh100>
      <button
        type="submit"
        disabled={!isDirty || isSubmitting || !isValid}
        className={clsx(
          'button-fill-primary',
          'button-large',
          'w-full',
          'max-w-[768px]',
          'v1-mobile:max-w-full',
          'rounded-none',
          'fixed',
          'bottom-0',
        )}
        data-ga="board_create_submit"
      >
        작성 완료
      </button>
    </form>
  )
}
