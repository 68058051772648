import Image from 'next/image'
import { useRouter } from 'next/navigation'
import clsx from 'clsx'
import { S3_IMAGE_URL } from '@/config'
import { URL_PATH } from '@/consts'

const EventBanner = () => {
  const { push } = useRouter()

  return (
    <div
      data-ga="main_preregistration_banner"
      className={clsx(
        'bg-plus-main-banner',
        'rounded-2xl',
        'p-5',
        'flex justify-between items-center',
        'w-full',
        'text-white',
      )}
      role="button"
      onClick={() => push(URL_PATH.PlusPreregistration)}
    >
      <h2 className="prose-p2 font-bold">
        의료팀의 전문적인 관리
        <br />
        지금 신청하면 3개월 무료!
      </h2>
      <Image
        alt=""
        src={`${S3_IMAGE_URL}/plus/plus-main-banner.png`}
        width={80}
        height={60}
      />
    </div>
  )
}

export default EventBanner
