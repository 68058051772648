/** 하단 버튼 높이 */
export const BUTTON_HEIGHT = 56

const getReverseObject = <T extends Record<string, string>>(obj: T) =>
  Object.fromEntries(Object.entries(obj).map(([key, value]) => [value, key]))

export const KOR_TO_ENG_TREATMENTS = {
  수술: 'SURGERY',
  '항암 치료': 'ANTICANCER',
  '방사선 치료': 'RADIOTHERAPY',
  '항호르몬 치료': 'HORMONAL',
  '방사성 요오드 치료': 'RADIOIODINE',
  '갑상선 호르몬제 복용': 'THYROID_HORMONE',
} as const

export const ENG_TO_KOR_TREATMENTS = getReverseObject(KOR_TO_ENG_TREATMENTS)

export const KOR_TO_ENG_CANCERS = {
  위암: 'STOMACH',
  갑상선암: 'THYROID',
  폐암: 'LUNG',
  대장암: 'COLON',
  유방암: 'BREAST',
} as const

export const ENG_TO_KOR_CANCERS = getReverseObject(KOR_TO_ENG_CANCERS)

export const KOR_TO_ENG_STATUSES = {
  예방: 'PREVENTION',
  의심: 'SUSPICION',
  진단: 'DIAGNOSIS',
  치료: 'TREATMENT',
  관리: 'MANAGEMENT',
  완치: 'CURE',
} as const

export const ENG_TO_KOR_STATUSES = getReverseObject(KOR_TO_ENG_STATUSES)

export const MAX_NUM_OF_IMAGES = 10
