import MaterialSymbol from '@/v1/MaterialSymbol'

const Description = () => {
  return (
    <div>
      <span className="prose-p3 font-medium">
        검진 결과를 이해하기 쉽게
        <br />
        해석해 드립니다.
      </span>

      <span className="flex items-center prose-p3 text-lavendar-500 mt-2 font-medium">
        <MaterialSymbol
          name="info_fill"
          className="fill-lavendar-500 mr-1"
          size={14}
        />
        주민등록번호는 가려주세요.
      </span>
    </div>
  )
}

export default Description
