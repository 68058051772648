import NiceModal from '@ebay/nice-modal-react'
import { API_PATH } from '@/consts/urls'
import AlertModal from '@/hooks/useModal/AlertModal'
import { handleRequest } from '../httpClient/handleRequest'

const ERROR_MESSAGE = (
  <div>
    이미지 업로드에 실패했습니다.
    <br />
    <strong>마이케어 {'>'} 질문함의 수정 화면</strong>에서
    <br />
    이미지를 다시 업로드 해주세요.
  </div>
)

/**
 * 게시글에 이미지 업로드하는 API 호출
 * @param id 게시글 상세 id
 * @param imageFiles 업로드할 이미지 배열
 */
export const handleImageUpload = async (id: number, formData: FormData) => {
  try {
    const response = await handleRequest(
      `${API_PATH.Qna}${id.toString()}/image/`,
      { method: 'post', body: formData },
    )
    return response
  } catch (error) {
    NiceModal.show(AlertModal, {
      title: '이미지 업로드 실패',
      message: ERROR_MESSAGE,
    })
    return null
  }
}
