import Image from 'next/image'
import clsx from 'clsx'
import MaterialSymbol from '@/v1/MaterialSymbol'

/**
 * 이미지 업로드 영역의 썸네일 이미지
 * @param hasUploadImage 브라우저에서 이미지 업로드 여부
 * @param thumbnailUrl 썸네일 이미지 url
 * @returns 썸네일 이미지 또는 이미지 추가 아이콘
 */
const Thumbnail = ({
  hasUploadImage,
  thumbnailUrl,
}: {
  hasUploadImage: boolean
  thumbnailUrl: string
}) => {
  if (!hasUploadImage) {
    return (
      <MaterialSymbol
        name="add_photo_alternate"
        className="fill-lavendar-500 mt-2.5"
        size={24}
      />
    )
  }

  return (
    <div
      className={clsx(
        'relative',
        'w-[72px]',
        'h-[72px]',
        'rounded-lg',
        'overflow-hidden',
      )}
    >
      <Image
        className={clsx(
          'rounded-lg',
          'absolute',
          'top-1/2 left-1/2',
          '-translate-x-1/2',
          '-translate-y-1/2',
          'w-auto h-auto',
          'min-w-[100%] min-h-[100%]',
          'object-cover object-center',
        )}
        alt="업로드된 이미지 미리보기"
        src={thumbnailUrl}
        width={72}
        height={72}
      />
    </div>
  )
}

export default Thumbnail
