import { ReactNode } from 'react'
import { URL_PATH } from '@/consts/urls'
import MaterialSymbol from '@/v1/MaterialSymbol'

type Menu = {
  text: string
  icon: ReactNode
  activeIcon: ReactNode
  path: string
  gtm: string
}

export const MENUS: Menu[] = [
  {
    text: '홈',
    icon: <MaterialSymbol name="home" />,
    activeIcon: <MaterialSymbol name="home_fill" className="fill-aqua-500" />,
    path: URL_PATH.Main,
    gtm: 'tab_main',
  },
  {
    text: '마이케어',
    icon: <MaterialSymbol name="person" />,
    activeIcon: <MaterialSymbol name="person_fill" className="fill-aqua-500" />,
    path: URL_PATH.MyCare,
    gtm: 'tab_mycare',
  },
  {
    text: '메뉴',
    icon: <MaterialSymbol name="menu" />,
    activeIcon: <MaterialSymbol name="menu" className="fill-aqua-500" />,
    path: URL_PATH.AppGnb,
    gtm: 'tab_menu',
  },
]

// 탭을 노출할 1depth 화면 경로
export const PATHS_WITH_TAB_BAR = [
  URL_PATH.Main,
  URL_PATH.MyCare,
  URL_PATH.GetStarted,
  URL_PATH.AppGnb,
  URL_PATH.GuidesMainBreast,
  URL_PATH.GuidesMainLung,
  URL_PATH.GuidesMainThyroid,
  URL_PATH.GuidesMainStomach,
  URL_PATH.GuidesMainColon,
  URL_PATH.GuidesLife,
  URL_PATH.Service,
  URL_PATH.Notices,
  URL_PATH.Boards,
  URL_PATH.HospitalsMain,
  URL_PATH.PlusSurvey,
  URL_PATH.MyPlusSurveyResult,
]
