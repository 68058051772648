import NiceModal from '@ebay/nice-modal-react'
import kyClient from 'ky'
import type { Options } from 'ky'
import { z } from 'zod'
import { REST_API_ENDPOINT } from '@/config'
import { MOBILE_EVENTS, REFRESH_TOKEN_NOT_FOUND_ERROR } from '@/consts'
import FullScreenModal from '@/hooks/useModal/FullScreenModal'
import { isWebview, postMessageToApp } from '@/utils'
import { getAuthToken, setAuthToken, logout } from '@/utils/auth'
import { normalizeError } from './normalizeError'

const schema = z.object({
  access: z.string(),
  refresh: z.string(),
})

type Response = z.infer<typeof schema>

/**
 * Handle HTTP request
 * @param url - URL to request
 * @param options - Options to pass to ky
 * @param onRetry - Callback to run when request is retried
 * @returns - JSON response
 * @throws - Error if response is not ok
 * @throws - Error if response is not JSON
 * @throws - Error if request fails
 * @throws - Error if request is aborted
 * @throws - Error if request times out
 *
 * @example: Request with options
 * handleRequest(url, {
 *   method: 'POST',
 *   body: JSON.stringify({ name: 'John Doe' })
 *   headers: {
 *     'Content-Type': 'application/json',
 *     'Authorization': `Bearer ${token}`,
 *   },
 *   timeout: 1000,
 *   retry: 3,
 *   prefixUrl: 'https://api.github.com',
 *   hooks: {
 *     beforeRequest: [
 *       request => {
 *         request.headers.set('X-Request-ID', '123')
 *       }
 *    ],
 * }))
 */
export const handleRequest = async <T>(
  url: string,
  options: Options,
  onRetry?: () => void,
) => {
  const { accessToken } = getAuthToken()

  try {
    const response = await kyClient(url, {
      ...options,
      prefixUrl: REST_API_ENDPOINT,
      hooks: {
        ...options?.hooks,

        afterResponse: options?.hooks?.afterResponse ?? [
          async (request, options, response) => {
            if (response.status === 401) {
              try {
                // 토큰 갱신
                const { access } = (await handleTokenRefresh()) ?? {}
                if (!access) return

                // 토큰을 갱신한 후에는 원래 요청을 재시도.
                const retryResponse = await kyClient(url, {
                  ...options,
                  prefixUrl: REST_API_ENDPOINT,
                  headers: {
                    ...options.headers,
                    Authorization: `Bearer ${access}`,
                  },
                })
                // 재시도가 성공하면 onRetry 실행
                if (retryResponse.ok && onRetry) {
                  onRetry?.()
                }
                return retryResponse
              } catch (refreshError) {
                throw refreshError
              }
            }
            return response
          },
        ],
      },
      headers: {
        ...options?.headers,
        Authorization: !!accessToken ? `Bearer ${accessToken}` : '',
      },
    })
    if (response.status === 204) return {} as T
    // 응답 본문이 json이 아닌 경우 빈 객체 반환하여 json 파싱 에러 방지
    const data =
      response.headers.get('Content-Type') !== 'application/json'
        ? ({} as T)
        : ((await response.json()) as T)
    return data
  } catch (error) {
    throw normalizeError(error)
  }
}

export const requestTokenRefresh = async (refreshToken: string) => {
  try {
    const response = await handleRequest<Response>('token/refresh/', {
      prefixUrl: REST_API_ENDPOINT,
      method: 'post',
      json: {
        refresh: refreshToken,
      },
      hooks: {
        afterResponse: [],
      },
    })
    const data = schema.parse(response)
    setAuthToken({
      accessToken: data.access,
      refreshToken: data.refresh,
    })
    return data
  } catch (error) {
    logout()
    throw error
  }
}

let tokenRefreshInProgress = false
let tokenRefreshPromise: Promise<Response> | null = null

/**
 * 토큰 재발급하여 토큰을 저장하고, 재발급 후 실행할 함수를 실행합니다.
 */
export const handleTokenRefresh = async () => {
  const isApp = isWebview()

  const { refreshToken } = getAuthToken()
  // 토큰 재발급 중인 경우, 재발급이 완료될 때까지 대기
  if (tokenRefreshInProgress) return tokenRefreshPromise

  try {
    if (!refreshToken) throw new Error(REFRESH_TOKEN_NOT_FOUND_ERROR)
    tokenRefreshInProgress = true
    tokenRefreshPromise = requestTokenRefresh(refreshToken)
    const newToken = await tokenRefreshPromise
    if (isApp) {
      // TODO: 다음 앱 버전에서는 리프레시 토큰 전달하지 않고, 앱에 있는 토큰을 사용하도록 변경
      postMessageToApp(MOBILE_EVENTS.TOKEN_REFRESH, {
        refresh: refreshToken,
      })
    }
    return newToken
  } catch (error) {
    NiceModal.hide(FullScreenModal)
    throw error
  } finally {
    tokenRefreshInProgress = false
  }
}
