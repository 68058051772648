import NiceModal from '@ebay/nice-modal-react'
import * as Sentry from '@sentry/nextjs'
import { URL_PATH } from '@/consts'
import BottomSheetModal from '@/hooks/useModal/BottomSheetModal'
import FullScreenModal from '@/hooks/useModal/FullScreenModal'
import { logout } from '@/utils/auth'
import { showBottomSheet, showErrorAlert, setExtra } from '@/utils/error'
import { isHTTPError } from '@/utils/httpClient/normalizeError'
import getErrorResponse from './getErrorResponse'
import {
  maintenanceErrorResponse,
  schema400Response,
  ErrorMessage,
} from './types'

/**
 * 공통 에러 처리 함수. http 에러가 아닌 경우 및 500 에러를 처리한다.
 * @param error
 */
export const handleCommonError = async (error: unknown) => {
  // 네트워크 문제, CORS, 타임아웃, 브라우저 정책 등으로 발생한 에러
  if (!isHTTPError(error)) {
    Sentry.captureException(error, setExtra)
    return
  }

  const { message, response } = error
  const { status } = response

  const errorResponse = (await getErrorResponse(status, response)) ?? message

  switch (status) {
    case 503: // Service Unavailable (긴급점검)
      if (isMaintenanceError(errorResponse)) {
        const errorResponse503 = await response.json()
        const { maintenanceStart, maintenanceEnd } =
          maintenanceErrorResponse.parse(errorResponse503)

        const query = new URLSearchParams({
          maintenanceStart,
          maintenanceEnd,
        }).toString()

        window.location.replace(`/503/?maintenanceStart=${query}`)

        NiceModal.hide(FullScreenModal)
        NiceModal.hide(BottomSheetModal)
        Sentry.captureException(error, setExtra)
        break
      }

    case 500: // Internal Server Error
    case 502: // Bad Gateway
    case 503: // Service Unavailable
    case 504: // Gateway Timeout
      window.location.href = '/500/'
      NiceModal.hide(FullScreenModal)
      Sentry.captureException(error, setExtra)
      break
    default:
      return {
        status,
        errorResponse,
      }
  }
}

/**
 * 긴급점검 에러인지 여부를 반환하는 함수
 */
const isMaintenanceError = (errorResponse: any) => {
  try {
    return maintenanceErrorResponse.parse(errorResponse)
  } catch (error) {}
}

/**
 * 에러가 발생했을 때 alertModal을 띄우고, 에러 코드를 반환하는 함수.
 * HTTPError가 아닌 경우 alert을 띄운다.
 * HTTPError의 경우 status에 따라 처리한다. (400, 403, 500 등)
 * @param error 에러 객체
 * @param ignoreCodes 직접 처리할 에러 코드 배열
 * @returns 에러 상태코드, 에러 메시지
 */
export const handleError = async (error: unknown, ignoreCodes?: number[]) => {
  const { status, errorResponse } = (await handleCommonError(error)) || {}
  // status가 undefined인 경우, handleCommonError에서 에러 처리를 이미 했으므로 더 이상 진행하지 않는다.
  if (status === undefined) return

  const message =
    typeof errorResponse === 'string' ? errorResponse : errorResponse?.detail

  /**
   * 공통 에러 처리
   */
  switch (status) {
    case 400:
      if (ignoreCodes?.includes(400)) {
        return {
          status,
          errorResponse,
        }
      }
      if (is400ErrorMessage(errorResponse)) {
        // 400 에러 메시지인 경우, 첫 번째 에러 메시지를 띄운다.
        showErrorAlert(Object.values(errorResponse)[0][0])
        Sentry.captureException(error, setExtra)
        break
      }

    case 401:
      if (ignoreCodes?.includes(401)) {
        return {
          status,
          errorResponse,
        }
      }
      logout()
      if (window.location.pathname !== URL_PATH.MyCare) showBottomSheet()
      break

    case 403:
      if (ignoreCodes?.includes(403)) {
        return {
          status,
          errorResponse,
        }
      }
      if (message)
        showErrorAlert(message, () => {
          window.location.href = URL_PATH.Main
        })
      break

    case 404:
      if (ignoreCodes?.includes(404)) {
        return {
          status,
          errorResponse,
        }
      }
      window.location.href = '/404/'
      break

    default:
      if (ignoreCodes?.includes(status)) {
        return {
          status,
          errorResponse,
        }
      }
      if (message) showErrorAlert(message)
      Sentry.captureException(error, setExtra)
  }
}

/**
 * 400 에러 메시지인지 확인하는 타입 가드 함수.
 * 400 에러 메시지는 { 필드명: [에러메시지] } 형태이다.
 * @example is400ErrorMessage( error.message )
 * @param message 에러 메시지 객체 또는 문자열
 * @returns 400 에러 메시지인지 여부
 */
const is400ErrorMessage = (
  message: unknown,
): message is Record<string, string[]> => {
  return schema400Response.safeParse(message).success
}

/**
 * 400 에러 메시지 객체에서 필드명에 해당하는 에러 메시지를 반환한다.
 * @param message 400 에러 메시지 객체
 * @param key 필드명
 * @returns 에러 메시지 문자열
 */
export const get400ErrorMessage = (message: ErrorMessage, key: string) => {
  if (typeof message === 'string') return message
  return message[key][0]
}
