import { useEffect } from 'react'
import { useRouter, useSearchParams } from 'next/navigation'
import { URL_PATH } from '@/consts'
import useToast from '@/hooks/useToast'
import { isWebview } from '@/utils'

/**
 * 앱에서 탈퇴 시 토스트 메시지를 띄운다.
 * 탈퇴 시 페이지 새로고침이 있으므로 주소창에 쿼리 파라미터를 전달하여 상태를 유지한다.
 * unregister=true 파라미터가 있을 경우에만 동작한다.
 */
const useAppUnregisterToast = () => {
  const isWeb = !isWebview()
  const { replace } = useRouter()
  const { showToast } = useToast()
  const searchParams = useSearchParams()

  useEffect(() => {
    // 웹이면 무시한다.
    if (isWeb) return
    // 웹뷰 탈퇴 시 unregister=true 파라미터를 전달한다. 이게 없으면 무시한다.
    const param = searchParams.get('unregister') ?? ''
    if (!param) return

    // 다음 이벤트 루프에서 실행되도록 setTimeout을 사용한다.
    // 이게 없으면 다른 동기적인 작업이 끝나기 전에 토스트 메시지가 뜨게 되어, 안보인다.
    setTimeout(() => {
      showToast({
        title: '회원 탈퇴가 완료되었습니다.',
      })
    }, 0)

    window.history.replaceState(null, '', URL_PATH.Main)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWeb, replace, searchParams])
}

export default useAppUnregisterToast
