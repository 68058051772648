import { useState } from 'react'
import { usePathname } from 'next/navigation'
import useSWR, { mutate } from 'swr'
import Question from '@/components/Board/Question'
import Complete from '@/components/Complete'
import {
  API_PATH,
  COMPLETE_MAIN_TEXT,
  COMPLETE_SUB_TEXT,
  URL_PATH,
} from '@/consts'
import { handleUpdate } from '@/containers/BoardView/BoardUpdate/BoardUpdateForm'
import { useModal } from '@/hooks/useModal'
import { AIAnswerResponse } from '@/types/board'
import {
  handleCustomError,
  handleError,
  handleRequest,
} from '@/utils/httpClient'
import Button from '@/v1/Button'
import MessageBox from '@/v1/MessageBox'
import SizedSpinner from '@/v1/Spinner/SizedSpinner'
import AIAnswer from './AIAnswer'

// AI 답변이 로딩 중일 때 보여지는 UI
const AIAnswerLoading = ({
  onRequestMedicalAnswer,
}: {
  onRequestMedicalAnswer: () => void
}) => {
  return (
    <div className="pt-11 pb-[88px] px-md">
      <SizedSpinner size="lg" />
      <p className="font-medium text-center mt-3">
        잠시만 기다려주세요.
        <br />
        AI가 답변을 작성하고 있습니다.
      </p>
      <div className="flex gap-x-2.5 justify-center items-center my-5">
        <hr className="text-grey-200 w-[132px]" />
        <span className="block prose-p2 font-medium text-grey-500">또는</span>
        <hr className="text-grey-200 w-[132px]" />
      </div>
      <p className="font-medium text-center mb-3">
        AI 답변을 기다리는 대신 <br />
        의료팀 답변을 받아보시겠어요?
      </p>
      <div className="w-[180px] mx-auto">
        <Button
          size="medium"
          variant="outlined"
          onClick={onRequestMedicalAnswer}
          data-ga="board_create_doctor_create"
        >
          의료팀에게 질문하기
        </Button>
      </div>
    </div>
  )
}

const AIAnswerModal = ({ boardId }: { boardId: number }) => {
  // 의료팀 상담 요청에 대한 옵션 노출여부
  const [isOptionsVisible, setIsOptionsVisible] = useState(true)
  const { fullScreenModal } = useModal()
  const pathname = usePathname()
  const { data } = useSWR<AIAnswerResponse>(
    boardId ? `${API_PATH.Qna}${boardId}/ai-answer/` : null,
    handleRequest,
    {
      onError: (error) => {
        handleCustomError({ error, ignoreCodes: [404] })
      },
      revalidateOnFocus: false,
      refreshInterval: (data) =>
        data?.status === 'PROGRESSING' || !data ? 2000 : 0,
    },
  )

  // 의료팀에게 답변 요청
  const handleRequestMedicalAnswer = () => {
    handleUpdate(boardId, { status: 'WAITING' })
      .then(() => {
        fullScreenModal.show({
          title: '',
          children: (
            <Complete
              type="boardCreate"
              mainContent={COMPLETE_MAIN_TEXT.boardCreate}
              subContent={COMPLETE_SUB_TEXT}
              id={boardId}
            />
          ),
          onClose: () => {
            // 내 질문함 페이지라면, 데이터 다시 불러오기
            if (pathname === URL_PATH.MyCareBoards) {
              mutate(API_PATH.MyQuestions)
            }
          },
        })
      })
      .catch((error) => handleError(error))
  }

  if (!data) return null

  return (
    <>
      {isOptionsVisible && (
        <div className="px-md">
          <MessageBox size="sm">
            <div className="prose-p3 font-bold">질문이 접수되었습니다</div>
            <div className="prose-p3 mt-1">
              작성하신 질문에 대해 AI가 답변을 제공합니다. 필요하시면 루닛케어
              의료팀의 답변도 요청하실 수 있습니다.
            </div>
          </MessageBox>
        </div>
      )}
      <Question
        title={data?.question.title ?? '질문 내용'}
        board_id={boardId}
        question={data?.question.content ?? ''}
        cancer={data?.question.cancer ?? ''}
        created_at={data?.question.created_at ?? ''}
      />
      <h1 className="prose-h5 my-md px-md">
        <span className="text-lavendar-500">A. </span>
        AI 답변
      </h1>
      {data.status === 'PROGRESSING' ? (
        <AIAnswerLoading onRequestMedicalAnswer={handleRequestMedicalAnswer} />
      ) : (
        <AIAnswer
          data={data}
          contentId={String(boardId)}
          onRequestMedicalAnswer={handleRequestMedicalAnswer}
          isOptionsVisible={isOptionsVisible}
          hideOptions={() => setIsOptionsVisible(false)}
        />
      )}
    </>
  )
}

export default AIAnswerModal
