'use client'

import { useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useAtomValue } from 'jotai'
import AppDownloadBannerWrapper from '@/components/AppDownloadBanner/AppDownloadBannerWrapper'
import CreateDocumentFAButton from '@/components/CreateDocumentFAButton'
import { Divider } from '@/components/Divider'
import Footer from '@/components/Footer'
import MainBottomSheet from '@/components/MainBottomSheet'
import { useModal } from '@/hooks/useModal'
import useSearchModal from '@/hooks/useSearchModal'
import { mainBottomSheetAtom } from '@/stores'
import { MainHeader } from '@/v1/Header/MainHeader'
import EventBanner from './EventBanner'
import List from './List'
import Menu from './Menu'
import HotlineNudge from './Nudges/Hotline'

dayjs.extend(utc)
dayjs.extend(timezone)

const Main = () => {
  const { openSearchModal } = useSearchModal()
  const mainBottomSheet = useAtomValue(mainBottomSheetAtom)
  const { bottomSheetModal } = useModal()
  // 한국 시간 (Asia/Seoul) 기준으로 현재 날짜 확인
  const now = dayjs().tz('Asia/Seoul')
  // 메인 바텀시트 미노출 조건 설정: 2025년 1월 31일 23:59:59까지
  const start = dayjs('2025-01-31T23:59:59+09:00').tz('Asia/Seoul')
  // 메인 바텀시트 노출여부
  const notBottomsheetAvailable = now.isAfter(start)

  const showCarePlusBottomSheet = useCallback(() => {
    bottomSheetModal.show({
      content: <MainBottomSheet />,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!mainBottomSheet) return
    if (notBottomsheetAvailable) return
    showCarePlusBottomSheet()
  }, [mainBottomSheet, notBottomsheetAvailable, showCarePlusBottomSheet])

  return (
    <>
      <AppDownloadBannerWrapper>
        <MainHeader onClick={openSearchModal} />
      </AppDownloadBannerWrapper>
      <div className="mt-2 px-md">
        <EventBanner />
      </div>
      <div className="p-md">
        <Menu />
      </div>
      <Divider className="border-grey-100 border-[5px]" />
      <List />
      <div className="px-md">
        <HotlineNudge />
      </div>
      <Footer />
      <CreateDocumentFAButton />
    </>
  )
}

export default Main
