import { useRef, useState } from 'react'
import Resizer from 'react-image-file-resizer'
import * as Sentry from '@sentry/nextjs'
import clsx from 'clsx'
import ImageCount from '@/components/ImageUploader/ImageCount'
import Thumbnail from '@/components/ImageUploader/Thumbnail'
import { ERROR_MESSAGES } from '@/consts'
import { useModal } from '@/hooks/useModal'
import { showErrorAlert } from '@/utils/error'
import MaterialSymbol from '@/v1/MaterialSymbol'
import { MAX_NUM_OF_IMAGES } from '../consts'
import Description from './Description'

const ACCEPT = '.jpg, .jpeg, .png, .heic'
const VALID_FILE_REG = /\.(jpg|jpeg|png|heic)$/i
const RESIZE_QUALITY = 80 // 압축률
const MAX_FILE_SIZE = 1024 * 1024 * 20 // 20MB
const MAX_SERVER_FILE_SIZE = 1024 * 1024 * 1 // 1MB
const FILE_SIZE_EXCEED_MESSAGE = `20MB 미만인 파일만\n업로드 가능합니다.\n 크기를 줄여 다시 시도해 주세요.`

type FileData = {
  resizedFile: File
  originalFile: File
}

/**
 * 이미지 업로드 영역
 * @param totalImages 이미지의 총 개수
 * @param thumbnailImage 노출될 썸네일 이미지 url
 * @param setImageFiles 이미지 파일를 저장하는 set 함수
 * @param onReset 이미지 초기화 시 실행할 함수
 */
const ImageUploader = ({
  totalImages,
  thumbnailImage,
  selectImages,
}: {
  totalImages: number
  thumbnailImage?: string
  selectImages: (images: File[]) => void
}) => {
  const [thumbnailUrl, setThumbnailUrl] = useState(thumbnailImage ?? '')
  const { alertModal } = useModal()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const hasUploadImage = totalImages > 0 && Boolean(thumbnailUrl)

  const setThumbnail = (files: File[]) => {
    if (files.length === 0) return
    // 썸네일 설정 (FileReader로 Base64 데이터 URL 생성)
    const fileReader = new FileReader()
    fileReader.addEventListener('load', () =>
      setThumbnailUrl(fileReader.result as string),
    )
    fileReader.readAsDataURL(files[0])
  }

  const showMaxSizeAlert = (message: string) => {
    alertModal.show({
      title: '파일 용량 제한',
      message,
      confirmButton: {
        text: '닫기',
      },
    })
  }

  /**
   * HEIC 파일을 JPEG로 변환
   * @param file
   * @returns 변환된 jpeg 파일
   */
  const handleHeic = async (file: File) => {
    if (typeof window === 'undefined') return file

    if (file.type !== 'image/heic' && file.type !== 'image/heif') return file

    try {
      // 동적으로 heic2any를 로드
      // heic2any는 React 컴포넌트가 아닌 일반 함수이기 때문에, nextjs dynamic import를 사용하지 못함.
      const heic2any = (await import('heic2any')).default
      // HEIC 파일을 JPEG로 변환
      const convertedBlob = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.5,
      })

      // Blob을 File 객체로 변환
      return new File(
        [convertedBlob as Blob],
        file.name.replace(/\.\w+$/, '.jpeg'),
        {
          type: 'image/jpeg',
        },
      )
    } catch (error) {
      // TODO: 에러 처리 제대로.
      return file
    }
  }

  const handleImageResize = async (file: File) => {
    // 파일 크기(MB) 기준으로 quality를 동적으로 설정
    const fileSizeMB = file.size / (1024 * 1024) // 원본 파일 크기  (MB)
    let maxSize: number

    if (fileSizeMB > 10) maxSize = 1024
    else maxSize = 2000

    // HEIC 파일인 경우, JPEG로 변환 후 리사이징. react-image-file-resizer에서 지원안함.
    if (file.type === 'image/heic' || file.type === 'image/heif') {
      const imageFile = await handleHeic(file)

      return new Promise((resolve) => {
        Resizer.imageFileResizer(
          imageFile,
          maxSize,
          maxSize,
          'jpeg',
          RESIZE_QUALITY,
          0,
          (uri) => resolve(uri),
          'file',
        )
      })
    }

    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxSize,
        maxSize,
        'jpeg',
        RESIZE_QUALITY,
        0,
        (uri) => resolve(uri),
        'file',
      )
    })
  }

  // 이미지 파일 유효성 검증을 수행하여, 이미지 파일을 저장 및 알럿을 보여준다.
  const handleImageValidation = async (images: File[]) => {
    let resizedImages: FileData[] = []
    const isOverMaxLimit = images.length > MAX_NUM_OF_IMAGES
    const invalidFormatFiles = images.find(
      (image) => !VALID_FILE_REG.test(image.name),
    )

    // 이미지 확장자 체크
    if (invalidFormatFiles) {
      alertModal.show({
        title: '파일 업로드 불가',
        message: ERROR_MESSAGES.getWrongFileFormatError(ACCEPT),
        confirmButton: {
          text: '닫기',
        },
      })
      const validImages = images.filter((image) =>
        VALID_FILE_REG.test(image.name),
      )
      selectImages(validImages)
      setThumbnail(validImages)
      return
    }

    // 유저의 이미지 업로드 단에서 용량 제한 초과 시
    if (images.some((file) => file.size > MAX_FILE_SIZE)) {
      showMaxSizeAlert(FILE_SIZE_EXCEED_MESSAGE)
    }

    // 이미지 리사이징
    try {
      const originalFiles = images.filter(
        (image) => image.size <= MAX_FILE_SIZE,
      ) // 용량 초과하는 이미지들은 제거
      const results = await Promise.allSettled(
        originalFiles.map((file) => handleImageResize(file) as Promise<File>),
      )
      // 성공한 작업만 필터링
      resizedImages = results
        .filter((result) => result.status === 'fulfilled') // 성공한 작업만
        .map((result, index) => ({
          resizedFile: (result as PromiseFulfilledResult<File>).value,
          originalFile: originalFiles[index], // 원본 파일 매칭
        }))
    } catch (error) {
      showErrorAlert(ERROR_MESSAGES.IMAGE_RESIZE_FAILED)
      return
    }

    // 서버 쪽 용량 제한 초과 시
    const oversizedFile = resizedImages.find(
      (file) => file.resizedFile.size > MAX_SERVER_FILE_SIZE,
    )

    // resizedImages에서 resizedFile만 추출
    const imageResizedFiles = resizedImages
      .filter((file) => file.resizedFile.size <= MAX_SERVER_FILE_SIZE)
      .map((file) => file.resizedFile)
    // 용량 초과한 이미지가 하나라도 있으면 얼럿을 보여주고, 센트리에 로그를 남긴다.
    if (oversizedFile) {
      showMaxSizeAlert('크기를 줄여 다시 시도해 주세요.')
      Sentry.captureException(new Error('이미지 크기가 1MB 초과합니다.'), {
        extra: {
          originalSizeMB: oversizedFile.originalFile.size,
          resizedSizeMB: oversizedFile.resizedFile.size,
        },
      })
    }

    selectImages(imageResizedFiles)
    setThumbnail(imageResizedFiles)

    if (isOverMaxLimit) {
      alertModal.show({
        title: '이미지 갯수 초과',
        message: `이미지는 최대 ${MAX_NUM_OF_IMAGES}장까지 \n첨부할 수 있습니다.`,
        confirmButton: {
          text: '닫기',
        },
      })

      // 최대 이미지 개수까지 잘라서 저장
      selectImages(imageResizedFiles.slice(0, MAX_NUM_OF_IMAGES))
    }
  }

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { files } = event.target
    if (!files || files.length === 0) return
    await handleImageValidation([...files])
    // 선택된 파일 초기화
    event.target.value = ''
  }

  const resetFileSelect = () => {
    setThumbnailUrl('')
    selectImages([])
    if (fileInputRef.current) fileInputRef.current.value = ''
  }

  return (
    <div className="px-md">
      <div className="flex justify-between items-center mb-3">
        <h5 className="prose-p1 text-grey-800">검진 결과지 첨부</h5>
      </div>
      <div className="bg-grey-100 rounded-2xl p-4 flex justify-between">
        <Description />
        <div className={clsx('relative', 'w-[72px]', 'h-[72px]')}>
          <label
            className={clsx(
              'bg-white',
              'rounded-lg',
              'w-full',
              'h-full',
              'relative',
              'flex',
              'justify-center',
              'cursor-pointer',
            )}
          >
            <Thumbnail
              hasUploadImage={hasUploadImage}
              thumbnailUrl={thumbnailUrl}
            />
            <ImageCount
              totalImageCount={totalImages}
              maxImageCount={MAX_NUM_OF_IMAGES}
            />
            <input
              type="file"
              multiple
              className="opacity-0 w-full h-full absolute top-0 left-0 z-[1]"
              accept={ACCEPT}
              onChange={handleFileSelect}
              data-ga="board_create_upload_photo"
              ref={fileInputRef}
            />
          </label>
          {hasUploadImage && (
            <button
              type="button"
              aria-label="이미지 제거"
              className={clsx(
                'absolute',
                'w-6',
                'h-6',
                '-top-2',
                '-right-2',
                'bg-white',
                'rounded-full',
                'flex',
                'items-center',
                'justify-center',
                'border',
                'border-grey-200',
                'z-[1]',
              )}
              onClick={resetFileSelect}
            >
              <MaterialSymbol
                name="close"
                className="fill-grey-800"
                size={16}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ImageUploader
