'use client'

import { ReactNode } from 'react'
import clsx from 'clsx'
import useToggleBodyScroll from '@/hooks/useToggleBodyScroll'
import Header, { BottomSheetHeader } from '@/v1/Modal/BottomSheet/Header'
import Modal from '@/v1/Modal/index'
import Overlay from '../Overlay'
import { bottomSheetStyle } from '../styles'

const MARGIN_TOP = 24 // 바텀시트 위의 24px 여백
const BOTTOMSHEET_HEADER_HEIGHT = 66 // 바텀시트 헤더의 높이

interface Props {
  header?: BottomSheetHeader
  content?: ReactNode
  fullHeight?: boolean
  onClose: () => void
  opened: boolean
}

const BottomSheet = ({
  header: headerProps,
  content,
  fullHeight,
  onClose,
  opened,
}: Props) => {
  useToggleBodyScroll(opened)

  if (!opened) return null

  return (
    <Modal>
      <BottomSheetInner
        onClose={onClose}
        header={headerProps}
        content={content}
        fullHeight={fullHeight}
      />
    </Modal>
  )
}

/**
 *
 * @param header 헤더에 필요한 prop
 * @param content 바텀시트 내부 컨텐츠
 * @param fullHeight 바텀시트 높이가 최대 높이로 고정되는지 여부
 * @param onClose 바텀시트 닫는 이벤트
 * @returns
 */
export const BottomSheetInner = ({
  header: headerProps,
  content,
  fullHeight = false,
  onClose,
}: Omit<Props, 'opened'>) => {
  const supportsDvh = CSS.supports('height', '100dvh') // CSS 지원 여부 확인

  const defaultMaxHeight = supportsDvh
    ? `calc(100dvh - ${MARGIN_TOP}px - ${BOTTOMSHEET_HEADER_HEIGHT}px)` // dvh를 지원하면 이 값 사용
    : `calc(100vh - ${MARGIN_TOP}px - ${BOTTOMSHEET_HEADER_HEIGHT}px)` // 폴백 값

  const height = supportsDvh
    ? `calc(100dvh - ${MARGIN_TOP}px)` // dvh를 지원하면 이 값 사용
    : `calc(100vh - ${MARGIN_TOP}px)` // 폴백 값

  const handleClose = () => {
    headerProps?.onClose?.()
    onClose()
  }

  return (
    <>
      <Overlay closeable={true} onClose={handleClose} />
      <div
        className={bottomSheetStyle}
        role="dialog"
        aria-labelledby="bottomSheetTitle"
      >
        {headerProps && (
          <Header
            id="bottomSheetTitle"
            {...headerProps}
            onClose={handleClose}
          />
        )}
        <div
          className={clsx('mx-auto', 'w-full', {
            'overflow-y-auto': !!headerProps,
          })}
          style={{
            // headerProps가 있는 경우: 해당 헤더 높이를 제외한 높이로 설정
            maxHeight: !headerProps ? height : defaultMaxHeight,
            ...(fullHeight && { height }),
          }}
        >
          {content}
        </div>
      </div>
    </>
  )
}

export default BottomSheet
