import { z } from 'zod'
import { CANCER_FILTERS, MAX_QNA_CONTENT_SIZE, QNA_STATUSES } from '@/consts'

export const boardCreateSchema = z.object({
  question: z.string().trim().min(1).max(MAX_QNA_CONTENT_SIZE),
  cancer: z.enum(CANCER_FILTERS).exclude(['ALL']),
  status: z.enum(QNA_STATUSES).nullish(),
})

export const boardCreateByAISchema = z.object({
  questions: z.array(z.string()),
})

const boardUpdateSchema = z.object({
  question: z.string().trim().min(1).max(MAX_QNA_CONTENT_SIZE).nullish(),
  cancer: z.enum(CANCER_FILTERS).exclude(['ALL']).nullish(),
  status: z.enum(QNA_STATUSES).nullish(),
})

export const responseSchema = z.object({
  id: z.number(),
  question: z.string(),
  answer: z.string(),
  status: z.enum(QNA_STATUSES),
  cancer: z.object({
    name: z.string(),
    parent: z.string(),
    is_major: z.boolean(),
  }),
  created_at: z.string(),
  tags: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    }),
  ),
})

export type BoardCreateForm = z.infer<typeof boardCreateSchema>
export type BoardCreateByAIPost = z.infer<typeof boardCreateByAISchema>
export type BoardCreateResponse = z.infer<typeof responseSchema>
export type BoardUpdateForm = z.infer<typeof boardUpdateSchema>

export type AIRecommendationForm = {
  status?: string | undefined
  cancer?: string | undefined
  treatments?: string[] | undefined
  interest?: string | undefined
}
